import React from "react";
import Helmet from "react-helmet";

const SwiftType = (props) => {
  const {
    contentTypes,
    solutions,
    tags,
    customTitle,
    customDesc,
    customFeatImg,
    customExcerpt,
    customPublishDate,
  } = props;

  // customFeatImg should be a URL string

  return (
    <Helmet>
      {customTitle && (
        <meta
          class="swiftype"
          name="custom_title"
          data-type="string"
          content={customTitle}
        />
      )}
      {customExcerpt && (
        <meta
          class="swiftype"
          name="custom_excerpt"
          data-type="string"
          content={customExcerpt}
        />
      )}
      {customPublishDate && (
        <meta
          class="swiftype"
          name="custom_published_date"
          data-type="string"
          content={customPublishDate}
        />
      )}
      {customDesc && (
        <meta
          class="swiftype"
          name="custom_desc"
          data-type="string"
          content={customDesc}
        />
      )}
      {customFeatImg && (
        <meta
          class="swiftype"
          name="custom_feat_img"
          data-type="string"
          content={customFeatImg}
        />
      )}
      {contentTypes &&
        contentTypes.map((contentType) => (
          <meta
            class="swiftype"
            name="content_type"
            data-type="string"
            content={contentType}
          />
        ))}
      {solutions &&
        solutions.map((solution) => (
          <meta
            class="swiftype"
            name="solution"
            data-type="string"
            content={solution}
          />
        ))}
      {tags &&
        tags.map((tag) => (
          <meta class="swiftype" name="tag" data-type="string" content={tag} />
        ))}
    </Helmet>
  );
};

export default SwiftType;

/*
========================== Trigger a recrawl =============================

(the # after /domains is the Domain ID which changes every time you re-add domain. It can be found at top of that domain's overview page.)

ENTIRE SITE:

curl -X PUT -H 'Content-Length: 0' 'https://api.swiftype.com/api/v1/engines/unitedlex/domains/5f5b891664441faa8189c7d0/recrawl.json?auth_token=C9e6cw8W62DzkJRzfm9y'



SINGLE PAGE:

curl -X PUT 'https://api.swiftype.com/api/v1/engines/unitedlex/domains/5f5b891664441faa8189c7d0/crawl_url.json' \
  -H 'Content-Type: application/json' \
  -d '{
        "auth_token": "C9e6cw8W62DzkJRzfm9y",
        "url": "https://develop.dgoxmpbda00h1.amplifyapp.com/about-unitedlex"
      }'

*/
